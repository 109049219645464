import styled from 'styled-components';
import { FaPhoneAlt, FaEnvelope, FaGithub, FaLinkedin } from 'react-icons/fa';
import CardSection from '../components/CardSection.jsx';
import transition from '../utilities/transition';

const BigContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    flex-direction: column;
    color: white;
    max-width: 1050px;
    margin-top: 10vw;

    @media (max-width: 1050px) {
        max-width: 100%;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 10vw;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 44px;

    @media (max-width: 768px) {
        gap: 12px;
        margin-bottom: 44px;
        text-align: center;
    }
`;

const Title = styled.h1`
    font-size: 3.25rem;
    font-weight: 700;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 2.5rem;
    }
`;

const Subtitle = styled.h2`
    font-size: 2rem;
    font-weight: 500;
    color: #ffffff80;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

const SubSubTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 500;
    color: #ffffff60;
    padding: 0;
    margin: 0;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`;

const SocialSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    padding: 20px;
    margin-top: 20px;

    @media (max-width: 768px) {
        maring-top: 50px;
        gap: 20px;
        padding: 10px;
    }
`;

const SocialIcon = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #e0e0e080;
    transition: color 0.3s ease;
    text-decoration: none;

    &:hover {
        color: #e0e0e0;
    }

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

function Home() {
    return (
        <BigContainer>
            <Container>
                <TitleContainer>
                    <Title>Joe Hachem</Title>
                    {/* <Subtitle>Computer Engineering Student</Subtitle> */}
                    <SubSubTitle>
                        Solving Real-World Problems with skills that AI can't
                        replace (yet)
                    </SubSubTitle>
                </TitleContainer>

                <CardSection />

                <SocialSection>
                    <SocialIcon href="tel:+96171786056" aria-label="Phone">
                        <FaPhoneAlt />
                    </SocialIcon>
                    <SocialIcon
                        href="mailto:joehachem290@gmail.com"
                        aria-label="Email"
                    >
                        <FaEnvelope />
                    </SocialIcon>
                    <SocialIcon
                        href="https://github.com/joehachemx"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="GitHub"
                    >
                        <FaGithub />
                    </SocialIcon>
                    <SocialIcon
                        href="https://linkedin.com/in/joe-hachem"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="LinkedIn"
                    >
                        <FaLinkedin />
                    </SocialIcon>
                </SocialSection>
            </Container>
        </BigContainer>
    );
}

export default transition(Home);
