import styled from "styled-components";
import React from "react";

const Container = styled.div`
    color: white;
    padding: 1rem;

    @media (max-width: 768px) {
        padding: 0.5rem;
    }
`

const RoadmapContainer = styled.div`
    max-width: 64rem;
    margin: 0 auto;

    @media (max-width: 768px) {
        max-width: 100%;
        padding: 0 1rem;
    }
`

const Timeline = styled.div`
    position: relative;
    height: ${props => props.height}px;

    @media (max-width: 768px) {
        height: ${props => props.height - 80}px;;
    }
`

const YearMarkers = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #ffffff60;

    @media (max-width: 768px) {
        font-size: 0.75rem;
    }
`;

const YearMark = styled.div`
    text-align: center;
`

const ExperienceBar = styled.div`
    position: absolute;
    height: 2.5rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.25rem;
    left: ${props => props.left}%;
    width: ${props => props.width}%;
    top: ${props => props.top}px;
    transition: background-color 0.3s ease;

    &:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.25);
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0.25rem;
        // Replace the RGB red with a color based on the type prop
        background-color: ${props => 
            props.type === 'exp' ? 'rgba(0, 123, 255, 0.7)' :
            props.type === 'uni' ? 'rgba(40, 167, 69, 0.7)' :
            'rgba(255, 255, 255, 0.2)'
        };
        padding: 4px;
        width: 2px;
        height: 2rem;
    }

    @media (max-width: 768px) {
        height: 2rem;
        top: ${props => props.top * 0.75}px; // Adjust the spacing for mobile
        &:before {
            height: 1.5rem;
        }
    }
`;

const ExperienceContent = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 0 0.5rem;
    font-size: 0.875rem;
    user-select: none;
    overflow: hidden;
    left: 12px;

    @media (max-width: 768px) {
        align-items: flex-start;
        font-size: 0.75rem;
    }
`

const ExperienceTitle = styled.span`
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
        font-size: 0.75rem;
        display: none;
    }
`

const ExperienceCompany = styled.span`
    font-size: 0.75rem;
    opacity: 0.75;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
        display: none;
    }
`

const CheckbockContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 12px;
    user-select: none;
`;

const Checkbox = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    font-size: 1rem;
    color: white;

    input {
      accent-color: ${props => 
          props.type === 'exp' ? '#007bff' : // Experience color
          props.type === 'uni' ? '#28a745' : // Education color
          '#ffffff' // Default color
      };
      width: 16px;
      height: 16px;
    }
`;

const experiences = [
    { id: "vovo", title: "Co-Founder | SWE", company: "VOVO Charging", startYear: 2023, endYear: 2024, startQuarter: 4, endQuarter: 2, type: 'exp'},
    { id: "pwc", title: "Intern", company: "PwC", startYear: 2023, endYear: 2023, startQuarter: 3, endQuarter: 3, type: 'exp' },
    { id: "tomorrow", title: "iOS Developer Intern", company: "Tomorrow Services", startYear: 2022, endYear: 2023, startQuarter: 3, endQuarter: 2, type: 'exp' },
    { id: "uni", title: "Bachelor of Computer and Communication Engineering", company: "USJ", startYear: 2021, endYear: null, startQuarter: 4, endQuarter: null, type: 'uni'},
]

const currentYear = new Date().getFullYear()
const startYear = Math.min(...experiences.map(e => e.startYear))
const totalYears = currentYear - startYear + 1
const currentQuarter = Math.floor(new Date().getMonth() / 3) + 1;

function Roadmap() {
    const [selectedTypes, setSelectedTypes] = React.useState(['exp', 'uni']);

    const handleCheckboxChange = (type) => {
        setSelectedTypes((prevSelected) => 
            prevSelected.includes(type)
            ? prevSelected.filter((t) => t !== type)
            : [...prevSelected, type]
        );
    };

    const filteredExperiences = experiences.filter(exp => selectedTypes.includes(exp.type));

    return (
        <Container>
            <RoadmapContainer>
                <Timeline height={experiences.length * 60 + 50}>
                <YearMarkers>
                    {Array.from({ length: totalYears }, (_, i) => startYear + i).map(year => (
                        <YearMark key={year}>{year}</YearMark>
                    ))}
                    <YearMark>{currentYear + 1}</YearMark>
                </YearMarkers>
                
                {filteredExperiences.map((exp, index) => {
                    const yearStartOffset = (((exp.startYear - startYear) / totalYears)) * 100
                    const qStartOffset = ((100 / totalYears) / 4 ) * (exp.startQuarter - 1)

                    const yearEndOffset = ((((exp.endYear ?? currentYear) - startYear) / totalYears)) * 100
                    const qEndOffset = ((100 / totalYears) / 4 ) * (exp.endQuarter ?? currentQuarter - 1)

                    const startOffset = yearStartOffset + qStartOffset
                    const endOffset = yearEndOffset + qEndOffset
                    const width = endOffset - startOffset

                    return (
                        <ExperienceBar
                            key={index}
                            left={startOffset}
                            width={width}
                            top={index * 60}
                            type={exp.type}
                        >
                            <ExperienceContent onClick={() => document.getElementById(exp.id)?.scrollIntoView({ behavior: 'smooth' })} > 
                                <ExperienceTitle>{exp.title}</ExperienceTitle>
                                <ExperienceCompany> {exp.company} </ExperienceCompany>
                            </ExperienceContent>
                        </ExperienceBar>
                    )
                })}
                </Timeline>
            </RoadmapContainer>


            <CheckbockContainer>
                <Checkbox type="exp">
                    <input
                        type="checkbox"
                        checked={selectedTypes.includes('exp')}
                        onChange={() => handleCheckboxChange('exp')}
                    />
                    Experience
                </Checkbox>

                <Checkbox type="uni">
                    <input
                        type="checkbox"
                        checked={selectedTypes.includes('uni')}
                        onChange={() => handleCheckboxChange('uni')}
                    />
                    Education
                </Checkbox>
            </CheckbockContainer>

            
        </Container>
    );
}

export default Roadmap;