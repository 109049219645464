import styled from 'styled-components';
import { SiPython, SiSwift, SiJavascript, SiKotlin, SiRust, SiKtor, SiReact, SiVisualstudiocode, SiMysql, SiUbuntu, SiFigma } from 'react-icons/si';
import React from 'react';
import transition from '../utilities/transition';

const BigContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    flex-direction: column;
    color: white;
    max-width: 1050px;
    margin-top: 5vw;

    @media (max-width: 1050px) {
        max-width: 100%;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 10vw;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 40px;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
`;

const Subtitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff80;
    padding: 0;
    margin: 0;
	text-align: center;
`;

const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
	width: 100%;
	align-items: flex-start;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
	align-items: flex-start;
    padding: 12px;
    transition: opacity 0.3s ease;
    font-size: 1.2rem;
    font-weight: 600;
    opacity: 0.8;
	border-radius: 12px;
	border: 1px solid #ffffff80;
	flex-grow: 1;

    &:hover {
        opacity: 1;
    }
`;

const CardTitleDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffffff;
    padding: 0;
    margin: 0;
	gap: 4px;
`;

const CardTitle = styled.h1`
    font-size: 1.25rem;
    font-weight: 600;
    color: #ffffff;
    padding: 0;
    margin: 0;
`;

const CardSubTitle = styled.h1`
    font-size: 0.8rem;
    font-weight: 400;
    color: #ffffff80;
    padding: 0;
    margin: 0;
`;

const SubContainerTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    color: #ffffff;
    padding: 0;
    margin: 0;
	padding-bottom: 24px;
`;

const CardUpperSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-bottom: 4px;
`;

const CardContext = styled.h1`
	font-size: 0.9rem;
	font-weight: 400;
	color: #2ECC71;
	padding: 0;
	margin: 0;
`;

const CardDescriptionText = styled.p`
	padding: 0;
	margin: 0;
	font-size: 0.9rem;
	font-weight: 400;
	color: #ffffff80;
	word-wrap: break-word;
	line-height: 1.5;
	padding-top: 8px;
`;

const CardDescription = ({ description }) => {
    return (
        <CardDescriptionText>
            {description.trim().split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </CardDescriptionText>
    );
};

const SubCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
	flex-wrap: wrap;
	margin-bottom: 32px;

    width: 100%;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

function Skills() {
	let programmingLanguages = [
		{
			icon: <SiSwift />,
			name: 'Swift',
			context: 'Professional, Personal',
			tech: 'Front-End',
			description: `
				• Currently working on the 'SwiftCN' Open Source Project
				• Worked on an iOS app called 'VOVO Charging'
				• Deployed an iOS app called 'Dentiflow' to the App Store during an internship
        	`
		},
		{
			icon: <SiKotlin />,
			name: 'Kotlin',
			context: 'Professional, Personal',
			tech: 'Back-End',
			description: `
				• Deployed a backend as a solo developer for 'Darresni' using the Ktor framework
				• Worked on a backend using the Ktor framework for 'VOVO Charging'
				• Worked on a Kotlin Multiplatform Project, 'Dentiflow' which is currently deployed
			`
		},
		{
			icon: <SiJavascript />,
			name: 'JavaScript',
			context: 'Professional, Personal',
			tech: 'Front-End | Back-End',
			description: `
				• Deployed a VS Code Extension during a group university project
				• Built and Shipped front-end websites using React 
				• Freelanced using Vanilla JavaScript for many front-end projects
			`
		},
		{
			icon: <SiRust />,
			name: 'Rust',
			context: 'Personal',
			tech: 'CLI Tools',
			description: `
				• Working on a CLI Tool for 'SwiftCN' 
				• Currently Learning in my spare time
			`
		},
		{
			icon: <SiPython />,
			name: 'Python',
			context: 'Personal, University',
			tech: 'Data Science',
			description: `
				• Used the 'Manim' library to produce elegant math animations for 'Darresni'
				• Used to solve problems in my spare time
			`
		}
	];

	let frameworksLibraries = [
		{
			icon: <SiSwift />,
			name: 'SwiftUI',
			context: 'Professional, Personal',
			tech: 'Front-End',
			description: `
				• Currently working on the 'SwiftCN' Project
				• Deployed an application to the App Store
				• Forked packages repositories and updated them
        	`
		}, {
			icon: <SiReact />,
			name: 'React',
			context: 'Professional, Personal',
			tech: 'Front-End',
			description: `
				• Currently working on 'Darresni'
				• Assisted a team of three interns during their web development journey, debugging their code and providing guidance
				• Deployed 3 websites
        	`
		},
		{
			icon: <SiKtor />,
			name: 'Ktor',
			context: 'Professional, Personal',
			tech: 'Back-End',
			description: `
				• Currently working and deployed the backend for 'Darresni'
				• Worked on HTTPS and WebSockets communications
        	`
		},
		{
			icon: <SiVisualstudiocode />,
			name: 'VS Code API',
			context: 'Professional, Personal',
			tech: 'Front-End',
			description: `
				• Deployed a VS Code Extension during a group university project where I was the team leader
				• Learned everything from documentations and debugging
        	`
		}
	]

	let databasesOther = [
		{
			icon: <SiMysql />,
			name: 'MySQL',
			context: 'Professional, Personal, Univeristy',
			tech: 'Database',
			description: `
				• Currently managing a Database for 'Darresni'
				• Took an Advanced Databases Course at University
        	`
		},
		{
			icon: <SiUbuntu />,
			name: 'Self-Hosting',
			context: 'Professional, Personal',
			tech: 'DevOps | VPS Management',
			description: `
				• Currently Self-Hosting a VPS for 'Darresni'
				• Worked on an Ubuntu Server for 'VOVO Charging'
        	`
		},
		{
			icon: <SiFigma />,
			name: 'Figma UI/UX',
			context: 'Professional, Personal',
			tech: 'UI/UX Design',
			description: `
				• Designed UI/UX for 'Darresni' and 'VOVO Charging'
				• Sketched UI/UX Desings for a lot of projects
        	`
		}
	]


    return (
		<BigContainer>
        <Container>
            <TitleContainer>
                <Title>Tech Stack</Title>
                <Subtitle>
                    Tools and Technologies I used to Ship Products
                </Subtitle>
            </TitleContainer>

            <SubContainer>
				<SubContainerTitle>Programming Languages</SubContainerTitle>
				<SubCardContainer>
					{
						programmingLanguages.map((language, index) => {
							return (
								<Card key={index}>
									<CardUpperSection>
										<CardTitleDiv>
											{language.icon}
											<CardTitle>{language.name}</CardTitle>
										</CardTitleDiv>

										<CardContext>{language.context}</CardContext>
									</CardUpperSection>
									
									<CardSubTitle>{language.tech}</CardSubTitle>

									<CardDescription description={language.description} />

								</Card>
							);
						})
					}
				</SubCardContainer>


				<SubContainerTitle>Frameworks & Libraries </SubContainerTitle>
				<SubCardContainer>
					{
						frameworksLibraries.map((language, index) => {
							return (
								<Card key={index}>
									<CardUpperSection>
										<CardTitleDiv>
											{language.icon}
											<CardTitle>{language.name}</CardTitle>
										</CardTitleDiv>

										<CardContext>{language.context}</CardContext>
									</CardUpperSection>
									
									<CardSubTitle>{language.tech}</CardSubTitle>
									
									<CardDescription description={language.description} />

								</Card>
							);
						})
					}
				</SubCardContainer>
                
				<SubContainerTitle>Databases | DevOps | Tools</SubContainerTitle>
				<SubCardContainer>
					{
						databasesOther.map((language, index) => {
							return (
								<Card key={index}>
									<CardUpperSection>
										<CardTitleDiv>
											{language.icon}
											<CardTitle>{language.name}</CardTitle>
										</CardTitleDiv>

										<CardContext>{language.context}</CardContext>
									</CardUpperSection>
									
									<CardSubTitle>{language.tech}</CardSubTitle>
									
									<CardDescription description={language.description} />

								</Card>
							);
						})
					}
				</SubCardContainer>
            </SubContainer>
        </Container>
		</BigContainer>
    );
}

export default transition(Skills);