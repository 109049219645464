import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    transition: opacity 0.3s ease;
    font-size: 1.2rem;
    font-weight: 600;
    opacity: 0.8;
    border-radius: 10px;
    border: 1px solid #ffffff80;
    flex-grow: 1;
    margin-bottom: 12px;
    cursor: pointer;
    max-width: 33%;

    &:hover {
        opacity: 1;
    }
`;

const Title = styled.h1`
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
`;

const Type = styled.h1`
    font-size: 1rem;
    font-weight: 500;
    color: #ffffff80;
    padding: 0;
    margin: 0;
`;

const Date = styled.p`
    font-size: 0.9rem;
    font-weight: 500;
    color: orange;
    padding: 0;
    margin: 0;
`;

const TitleDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    color: #ffffff;
    width: 100%;
    padding: 0;
    margin: 0;
`;

const UpperSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 4px;
    margin-bottom: 16px;
`;

const Description = styled.p`
    font-size: 0.8rem;
    font-weight: 400;
    color: #ffffff90;
    word-wrap: break-word;
    line-height: 1.5;
    padding: 0;
    margin: 0;
`;

function BlogItem({ blog }) {
    return (
        <Container>
            <UpperSection>
                <TitleDiv>
                    <Title>{blog.title}</Title>
                    <Date>{blog.date}</Date>
                </TitleDiv>
                
                <Type>{blog.type}</Type>
            </UpperSection>

            <Description>
                {blog.description}
            </Description>
            
        </Container>
    );
}

export default BlogItem;