import styled from 'styled-components';
import BlogItem from '../components/BlogItem.jsx';
import transition from '../utilities/transition.js';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    flex-direction: column;
    color: white;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 5vw;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 40px;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
`;

const Subtitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff80;
    padding: 0;
    margin: 0;
    text-align: center;
`;

const SubContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
    align-items: flex-start;
`;


function Blog() {
    let blogs = [
        {
            id: 'blog 1',
            title: 'Its a Sample Blog',
            type: 'Personal',
            date: '2023-01-01',
            description: 'This is a dummy blog description that does not mean anything, I just want to see how it looks if the description is long enough. Most of this code is AI generated btw'
        },
        {
            id: 'blog 2',
            title: 'Its a Sample Blog',
            type: 'Personal',
            date: '2023-01-01',
            description: 'This is a dummy blog description that does not mean anything, I just want to see how it looks if the description is long enough. Most of this code is AI generated btw'
        },
        {
            id: 'blog 3',
            title: 'Its a Sample Blog',
            type: 'Personal',
            date: '2023-01-01',
            description: 'This is a dummy blog description that does not mean anything, I just want to see how it looks if the description is long enough. Most of this code is AI generated btw'
        }
    ]
    return (
        <Container>
            <TitleContainer>
                <Title>Blog</Title>
                <Subtitle>My Space to talk about things that I'm passionate about</Subtitle>
            </TitleContainer>
        
            <SubContainer>
                {
                    blogs.map((blog, index) => {
                        return (
                            <BlogItem key={index} blog={blog} />
                        )
                    })
                }

            </SubContainer>
        </Container>
    )
}

export default transition(Blog);