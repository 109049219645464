import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';


const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: center;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  transition: opacity 0.3s ease;
  font-size: 1.2rem;
  font-weight: 600;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    padding: 8px;
    font-size: 1rem;
  }
`;



function CardSection() {
    const navigate = useNavigate();
    
    return (
        <CardsContainer>
            <Card onClick={() => navigate('/about')}>About</Card>
            <Card onClick={() => navigate('/experience')}>Experience</Card>
            <Card onClick={() => navigate('/techstack')}>Tech Stack</Card>
            <Card onClick={() => navigate('/projects')}>Projects</Card>
            {/* <Card onClick={() => navigate('/blog')}>Blog</Card> */}
        </CardsContainer>
    );
}

export default CardSection;