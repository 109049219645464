import styled from 'styled-components';
import transition from '../utilities/transition';

const BigContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    flex-direction: column;
    color: white;
    max-width: 1050px;
    margin-top: 5vw;

    @media (max-width: 1050px) {
        max-width: 100%;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 10vw;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 40px;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
`;

const Subtitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff80;
    padding: 0;
    margin: 0;
    text-align: center;
`;

const SubContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    transition: opacity 0.3s ease;
    font-size: 1.2rem;
    font-weight: 600;
    opacity: 0.8;
    border-radius: 12px;
    border: 1px solid #ffffff80;
    flex-grow: 1;
    max-width: calc(50% - 40px);
    min-width: 30%;

    &:hover {
        opacity: 1;
    }

    @media (max-width: 768px) {
        flex-basis: 100%;
        max-width: 100%;
    }
`;

const CardTitleDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: #ffffff;
    padding: 0;
    margin: 0;
`;

const CardTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
`;

const CardType = styled.h1`
    font-size: 0.9rem;
    font-weight: 400;
    color: #ffffff80;
    padding: 0;
    margin: 0;
`;

const CardDescriptionText = styled.p`
    font-size: 0.8rem;
    font-weight: 400;
    color: #ffffff80;
    word-wrap: break-word;
    line-height: 1.5;
    padding-top: 8px;
    padding: 0;
    margin: 0;
`;

const CardUpperSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 12px;
    gap: 24px;
`;

const CardDate = styled.p`
    font-size: 1rem;
    font-weight: 500;
    color: #2ECC71;
    padding: 0;
    margin: 0;
`;

const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
`;

const CardBodySection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
`;

const NoHglt = styled.span`
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
    color: #ffffff90;
`;

const CardBodyTile = styled.h1`
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
`;

const CardBodyDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

const CardBodyItem = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff90;
    padding: 0;
    margin: 0;
`;

const State = styled.span`
    font-size: 0.8rem;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
    background-color: ${props => 
        props.state === 'Building' ? '#F39C12' :
        props.state === 'Alpha Testing' ? '#4c1d95' :
        props.state === 'Shipped' ? '#166534' :
        '#7F8C8D'
    };
    border-radius: 4px;
    padding: 4px;
    user-select: none;
`;

const Link = styled.a`
    color: #ffffff90;
    z-index: 999;

    &:hover {
        cursor: pointer;
    }
`;

function Projects() {
    let projects = [
        {
            id: 'swiftcn',
            title: 'SwiftCN',
            type: 'Open Source',
            date: '2024-Present',
            context: 'Open Source',
            techStack: 'Rust, SwiftUI',
            description: 'The ShadCN Alternative for SwiftUI. It is a collection of components that can be copy/pasted into your project. Built on top of SwiftUI and providing a CLI tool for faster and more efficient work.',
            proudOf: 'I am proud of the tech stack used to build the CLI, which is Rust. A language that I have been learning recently and I am very excited about the idea of building real projects using it.',
            learned: 'While building this project, every single commit counts. Given that the code will be open source, using shortcuts is not an option and We are forced to write clear code. I believe that this is very important experience to have as a developer.',
            contribution: 'Built the CLI in Rust, Developer on the Swift Components',
            state: 'Building'
        },
        {
            id: 'leprojet',
            title: 'Darresni',
            type: 'Full-Stack',
            date: '2024-Present',
            context: 'Personal',
            techStack: 'Ktor, React',
            checkItOut: <a href='https://darresni.io' target="_blank" style={{color: '#ffffff80'}}>darresni.io</a>,
            description: '\'Darresni\' is a platform that helps lebanese students excel in the official grade 9 exam. It is powered by AI and every course is desgined by me. I have been a math and physics tutor for the last 4 years.', 
            proudOf: 'The speed in which I shipped the MVP was sensational, even as a solo developer.',
            learned: 'I learned how to listen to customer feedbacks and improve my product', 
            contribution: 'Solo Developer',
            state: 'Alpha Testing'
        },
        {
            id: 'VOVO',
            title: 'VOVO Charging',
            type: 'Software',
            note: 'This section was intentionally left vague.',
            date: '2023-2024',
            context: 'Professional',
            techStack: 'Ktor, React, SwiftUI',
            description: 'VOVO Charging is a startup based in Germany specializing in the Electric Mobility Market.',
            learned: 'A lot. It was a crazy journey, a lot of ups and downs. Grateful for the amazing team.',
            contribution: 'Former Co-Founder and Backend Developer, responsible for HR, iOS App development, and Team Leader of the web development interns.',
        },
        {
            id: 'pwc',
            title: 'Business Continuity Training',
            type: 'Presentation',
            date: '2023',
            context: 'Professional',
            techStack: '',
            description: 'As an intern in the technology risk line of service at PwC Middle East, I spent most of my time there benchmarking 7 different well-known business continuity standards to present them to a big public administration in Qatar. I also contributed to the auditing of big companies cybersecuties and IT infrastructure.',
            proudOf: 'Benchmarking 7 different standards and presenting them clearly in a powerpoint presentation is a tedious and sensitive task, especially given the context. I showcased my focus, determination and meticulous work during this time.',  
            learned: 'I learned how cybersecurity and IT governance is handled in big firms and how to work in high stakes projects and clients.',
            contribution: 'I presented my research to my supperiors, then worked on the presentation with a team. I also used my engineering background and attention to details to write and review procedures in IT and cybersecurity.',
            state: 'Shipped'
        },
        {
            id: 'DGP',
            title: 'Documentation Generator',
            type: 'VS Code Extension',
            date: '2023',
            context: 'University',
            techStack: 'JavaScript, VS Code API',
            checkItOut: <> Install it from <Link href='https://marketplace.visualstudio.com/items?itemName=joehachem.documentation-generator-pii'>here</Link>. Source code is <Link href='https://github.com/joehachemx/documentation-generator-pii'> here</Link></>,
            description: 'This extension, which was a university course project, is a tool for developers to document their code faster. It also have an integration of OpenAI\'s API for smart comments. This project is not maintained at the moment, we shipped it before our final presentation.',
            proudOf: 'Condidering that this was a university projet, a simple script was expected from us. I am proud at the fact that we had the ambition, creativity and work ethic to make an extension and releasing it to the marketplace. It was fun to see our fellow classmates install and try our product during the final presentation.',
            learned: 'I learned how to meticulously read API documentations. In fact, building VS Code extensions are not very popular in the community and there were not many great resource online. We were forced to think like engineers and debug problems on our own.',
            contribution: 'Team leader of this group project',
            state: 'Shipped'
        },
        {
            id: 'dentiflow',
            title: 'Dentiflow',
            type: 'iOS App',
            date: '2022-2023',
            context: 'Professional',
            techStack: 'SwiftUI, Kotlin Multiplatfrom, Figma',
            checkItOut: <> The code is closed source. You can find the app on the <Link href='https://apps.apple.com/us/app/dentiflow/id1240853986'>Apple App Store.</Link></>,
            description: '\'Dentiflow\' is a cloud-based practice management software tailored for dentists.',  
            proudOf: 'I shipped this mobile app during my internship, where I had no prior knowledge of mobile development. I am proud of my hard work and passion during this time where I learned everything from scratch and shipped the product. Also, due to lack of resources, I had to personally design some parts of the application. Even though I was an intern, considering I was working at a Startup, my opnion on things was actually taken into consideration.', 
            learned: 'As this was my first production application, I learned how to take rational coding decisions on the design patterns used and structure, making it easier for future developers to update my code. Also, I experienced for the first time what it feels to be part of a startup environment.',
            contribution: 'Main developer for the iOS UI',
            state: 'Shipped'
        }
    ]

    return (
        <BigContainer>
        <Container>
            <TitleContainer>
                <Title>Projects</Title>
                <Subtitle>Relevant Projects I have worked on</Subtitle>
            </TitleContainer>

            <SubContainer>
                {
                    projects.map((project, index) => {
                        return (
                            <Card id={project.id} key={index}>
                            <CardUpperSection>
                                <CardTitleDiv>
                                    <CardTitle>{project.title}</CardTitle>
                                    <CardType>{project.type}</CardType>
                                </CardTitleDiv>
        
                                <CardDate>{project.date}</CardDate>
                            </CardUpperSection>
        
                            <CardBody>
                                <CardBodySection>
                                    <CardBodyTile>Context | <NoHglt>{project.context}</NoHglt> </CardBodyTile>
                                </CardBodySection>

                                {
                                    project.state &&
                                    <CardBodySection>
                                        <CardBodyTile>State  | <State state={project.state}>{project.state}</State></CardBodyTile>
                                    </CardBodySection>
                                }
        
                                {
                                    project.checkItOut &&
                                    <CardBodySection>
                                        <CardBodyTile>Check it out!</CardBodyTile>
                                        <CardBodyItem>{project.checkItOut}</CardBodyItem>
                                    </CardBodySection>
                                }
                                

                                {
                                    project.techStack && 
                                    <CardBodySection>
                                        <CardBodyTile>Tech Stack</CardBodyTile>
                                        <CardBodyDescription>
                                            <CardBodyItem>{project.techStack}</CardBodyItem>
                                        </CardBodyDescription>
                                    </CardBodySection>
                                }
        
                                <CardBodySection>
                                    <CardBodyTile>Description</CardBodyTile>
                                    <CardBodyItem>{project.description}</CardBodyItem>
                                </CardBodySection>
        
                                {
                                    project.proudOf &&
                                    <CardBodySection>
                                        <CardBodyTile>Something I'm proud of</CardBodyTile>
                                        <CardBodyItem>{project.proudOf}</CardBodyItem>
                                    </CardBodySection>
                                }
        
                                <CardBodySection>
                                    <CardBodyTile>Things I learned</CardBodyTile>
                                    <CardBodyItem>{project.learned}</CardBodyItem>
                                </CardBodySection>
        
                                <CardBodySection>
                                    <CardBodyTile>My Contribution</CardBodyTile>
                                    <CardBodyItem>{project.contribution}</CardBodyItem>
                                </CardBodySection>

                                {
                                    project.note &&
                                    <CardBodySection>
                                        <CardBodyTile>Note</CardBodyTile>
                                        <CardBodyItem>{project.note}</CardBodyItem>
                                    </CardBodySection>
                                }
                            </CardBody>
                        </Card>
                        )
                    })
                }
            </SubContainer>
        </Container>
        </BigContainer>
    );
}

export default transition(Projects);
