import styled from 'styled-components';
import ExperienceCard from '../components/ExperienceCard.jsx';
import transition from '../utilities/transition';
import Roadmap from '../components/Roadmap.jsx';


const BigContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    flex-direction: column;
    color: white;
    max-width: 1050px;
    margin-top: 5vw;

    @media (max-width: 1050px) {
        max-width: 100%;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 10vw;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 40px;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
`;

const Subtitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff80;
    padding: 0;
    margin: 0;
    text-align: center;
`;

const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
    align-items: flex-start;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 12px;
    width: 100%;
`;

const SectionTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    color: #ffffff;
    padding: 0;
    margin: 0;
    padding-bottom: 24px;
`;

function Experience() {
    let experience = [
      {
        id: 'vovo',
        title: 'Co-Founder | Software Engineer',
        company: 'VOVO Charging',
        date: 'October 2023 - July 2024',
        responsibilities: `
        As a Founder at the time, I was responsible for the early stage development of the startup
        I was also responsible for the recrutement and leadership of a team of web development interns and mobile developers 
        I also worked on the backend
        `,
        projects: `
        VOVO Charging iOS App
        VOVO Charging Web Dashboard
        VOVO Charging Backend
        `,
        skills: `
        Managerial Skills
        Founder Mode
        SwiftUI - ReactJS - Ktor
        `,
      },
      {
        id: 'pwc',
        title: 'Technology Risk Intern',
        company: 'PwC Middle East',
        date: 'July 2023 - August 2023',
        responsibilities: `
        Benchmarked 7 well-known standards in Business Continuity and assisted in preparing an awareness and training presentation for a big public administration in Qatar
        Assisted in writing and reviewing policies and procedures in IT and Cybersecurity
        `,
        projects: `
        Training Presentation on Business Continuity Standards
        `,
        skills: `
        Powerpoint & Excel
        
        `,
      },
      {
        id: 'tomorrow',
        title: 'iOS Mobile Developer Intern',
        company: 'Tomorrow Services',
        date: 'August 2022 - June 2023',
        responsibilities: `
        As an intern with no prior professional experience, I was tasked with learning the SwiftUI framework from scratch and building the UI 
        for 'Dentiflow', a mobile application offering a cloud solution for dentists to manage their clinics.
        My internship ended with a successful project, currently available on the App Store.
        In my last few weeks, I also took part in the development of the 'USJ FMD Conference App', which was a mobile application
        in collaboration with the University of Saint Joseph's Faculty of Medicine and Dentistry for their 100th aniversary.
        I participated in small UI tasks and was an IT support during the 4-day conference live at the scene.
        `,
        projects: `
        Denfiflow - iOS App
        `,
        skills: `
        SwiftUI
        Kotlin Multiplatform
        `,
      }
    ]

    let education = [
      {
        id: 'uni',
        title: 'Bachelor of Computer and Communication Engineering',
        company: 'Saint Joseph\'s University of Beirut, Lebanon',
        date: '2021 - 2026',
        grade: 'Grade: 17.33/20',
        awards: `
        3x Scolarships from Societe Murex | 2022-23-24
        Currently ranked in the top 7% of my class
        `,
        activities: `
        Class Representative for 2024-2025
        Organizer at the Google Developer Group on Campus | 2024-25 
        Secretary of the Google Developer Student Club | 2023-24
        `, 
      },
      {
        id: 'ecole',
        title: 'French Baccalaureate',
        company: 'Collège Notre-Dame de Jamhour, Lebanon',
        date: '2021',
        grade: 'Grade: 16/20',
        activities: 'Scout from 2013 to 2023',
      }
    ]

    return (
        <BigContainer>
        <Container>
            <TitleContainer>
                <Title>Experience</Title>
                <Subtitle>My relevant Experience and Education</Subtitle>
            </TitleContainer>

            <SubContainer>
                <Section>
                    <SectionTitle>Timeline</SectionTitle>

                    <Roadmap/>
                </Section>

                <Section>
                    <SectionTitle>Professional Experience</SectionTitle>
                    {
                        experience.map((exp, index) => {
                            return (
                                <ExperienceCard key={index} exp={exp} id={exp.id}/>
                            )
                        })
                    }
                </Section>

                <Section>
                    <SectionTitle>Education</SectionTitle>
                    {
                        education.map((exp, index) => {
                            return (
                                <ExperienceCard key={index} exp={exp} id={exp.id} />
                            )
                        })
                    }
                </Section>
            </SubContainer>
        </Container>
        </BigContainer>
    );
}

export default transition(Experience);
