import './App.css';
import styled from 'styled-components';
import { Route, Routes } from 'react-router-dom';
import Home from './scenes/Home.jsx';
import Skills from './scenes/Skills.jsx';
import About from './scenes/About.jsx';
import Experience from './scenes/Experience.jsx';
import Blog from './scenes/Blog.jsx';
import Projects from './scenes/Projects.jsx';
import { AnimatePresence } from 'framer-motion';


function App() {
  return (
    <>
      <AnimatePresence mode='wait'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/techstack" element={<Skills />} />
          <Route path="/projects" element={<Projects />} />

          <Route path="/blog" element={<Blog />} />


          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
