import styled from 'styled-components';
import transition from '../utilities/transition';
import ProfileCard from '../components/ProfileCard';

const BigContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #121212;
    flex-direction: column;
    color: white;
    max-width: 1050px;
    margin-top: 5vw;

    @media (max-width: 1050px) {
        max-width: 100%;
        margin-left: 5vw;
        margin-right: 5vw;
        margin-top: 10vw;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 40px;
`;

const Title = styled.h1`
    font-size: 2.5rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
`;

const Subtitle = styled.h2`
    font-size: 1.25rem;
    font-weight: 500;
    color: #ffffff80;
    padding: 0;
    margin: 0;
    text-align: center;
`;

const SubContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
	width: 100%;
	align-items: flex-start;
`;

const SectionTitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 700;
    color: #ffffff;
    padding: 0;
    margin: 0;
	padding-bottom: 8px;
`;

const SectionContent = styled.p`
    font-size: 1.1rem;
    font-weight: 500;
    color: #ffffff95;
    padding: 0;
    margin: 0;
	// padding-bottom: 12px;
    line-height: 1.5;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // margin-bottom: 12px;
`

const Hglt = styled.span`
    color: #ffffff;
    font-weight: 600;
`

const BottomSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    margin-bottom: 50px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

function About() {
    return (
        <BigContainer>
        <Container>
            <TitleContainer>
                <Title>About</Title>
                <Subtitle>
                    A rare place on the Internet where you can learn about me
                </Subtitle>
            </TitleContainer>

            <SubContainer>
                <ProfileCard/>

                <Section>
                    <SectionTitle>Background</SectionTitle>
                    <SectionContent>
                        I wrote my first line of code when I was 12 years old, it was a Windows configuration script that changes the icon of a USB drive.
                        My classmates were impressed and this script was on everybody's flash drives a week later. <br/>

                        This might sound like a small and insignificant achievement, knowing that a simple google search would have revealed the solution. But I learned something important that day.
                        <Hglt> It's not how complex the problem or the solution is, but how useful it is. </Hglt> I solved a problem that didn't exist and people were actually excited about it.
                        <br/> 
                    </SectionContent>
                    <SectionContent>
                        Since then, solving real world problems and learning how computers work has been my passion. I am a <Hglt> consistent and ambitious </Hglt> person with a <Hglt>strong work ethic </Hglt> and a desire to <Hglt> positively impact my environment.</Hglt> <br/> 
                        With arguably good personal and professional experience, despite not having graduated yet, I believe that my motivation and my passion for learning and innovating will help me build a bright future ahead.
                    </SectionContent>
                </Section>


                <BottomSection>
                    <Section style={{ flexBasis: '48%' }}>
                        <SectionTitle>Goals I'm working towards</SectionTitle>
                        <SectionContent>
                            Solving real-world business problems with skills that AI can't replace. <br/>
                            Becoming a better engineer and problem solver. <br/> 
                        </SectionContent>
                    </Section>

                    {/* Separator Line */}
                    {/* <div style={{
                        width: '1px',  // line thickness
                        height: 'auto',  // auto-height to match the content
                        backgroundColor: 'rgba(255, 255, 255, 0.6)',  // white with 80% opacity
                        alignSelf: 'stretch',  // ensures the separator stretches to the tallest item
                    }}></div> */}

                    <Section style={{ flexBasis: '48%' }}>
                        <SectionTitle>Interests</SectionTitle>
                        <SectionContent>
                            Currently learning the <Hglt>Rust</Hglt> programming language in my spare time. <br/> 
                            Passionate about <Hglt> how money and value creation works</Hglt>. <br/>
                            Looking forward to learn more about <Hglt>Private Equity</Hglt>. <br/>
                        </SectionContent>
                    </Section>
                </BottomSection>

                {/* <Section>
                    <SectionTitle>Soft Skills</SectionTitle>
                    <SectionContent>
                        - Entrepreneurial Mindset <br/>
                        - Leadership <br/>
                        - Time Management <br/>
                        - Communication <br/>
                        - Problem Solving <br/>
                        - Adaptability
                    </SectionContent>
                </Section> */}

                {/* <Section>
                    <SectionTitle>Motivations and Inspirations</SectionTitle>
                    <SectionContent>
                        bux <br/>
                    </SectionContent>
                </Section> */}

                {/* <Section>
                    <SectionTitle>Awards and Achievements</SectionTitle>
                    <SectionContent>
                        Top 1% Shawn Mendes listener in 2023 <br/>
                    </SectionContent>
                </Section> */}
            </SubContainer>
        </Container>
        </BigContainer>
    );
}

export default transition(About);