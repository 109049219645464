import styled from "styled-components";
import React from "react";


const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    transition: opacity 0.3s ease;
    font-size: 1.2rem;
    font-weight: 600;
    opacity: 0.8;
    border-radius: 10px;
    border: 1px solid #ffffff80;
    flex-grow: 1;
    margin-bottom: 12px;

    &:hover {
        opacity: 1;
    }
`;

const CardUpperSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 12px;
    gap: 24px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        padding-bottom: 16px;
    }
`;

const CardDate = styled.p`
    font-size: 1rem;
    font-weight: 500;
    color: #2ECC71;
    padding: 0;
    margin: 0;
`;

const CardTitle = styled.h1`
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
`;

const CardType = styled.h1`
    font-size: 1rem;
    font-weight: 600;
    color: #ffffff80;
    padding: 0;
    margin: 0;
`;

const CardTitleDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 8px;
    color: #ffffff;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
`;

const CardBodySection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
`;

const CardBodyTile = styled.h1`
    font-size: 1rem;
    font-weight: 600;
    padding: 0;
    margin: 0;
`;


const CardDescription = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: #ffffff90;
    padding: 0;
    margin: 0;
`;

const CardBodyItem = ({ description }) => {
    return (
        <CardDescription>
            {description.trim().split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </CardDescription>
    );
};

function ExperienceCard({ exp }) {
    return (
        <Card id={exp.id}>
            <CardUpperSection>
                <CardTitleDiv>
                    <CardTitle>{exp.title}</CardTitle>
                    <CardType>at {exp.company}</CardType>
                </CardTitleDiv>

                <CardDate>{exp.date}</CardDate>
            </CardUpperSection>

            <CardBody>
                {
                    exp.responsibilities != null &&
                    <CardBodySection>
                        <CardBodyTile>My Responsibilities</CardBodyTile>
                        <CardBodyItem description={exp.responsibilities}/>
                    </CardBodySection>
                }

                {
                    exp.projects != null &&
                    <CardBodySection>
                        <CardBodyTile>Projects</CardBodyTile>
                        <CardBodyItem description={exp.projects}/>
                    </CardBodySection>
                }

                {
                    exp.skills != null &&
                    <CardBodySection>
                        <CardBodyTile>Skills</CardBodyTile>
                        <CardBodyItem description={exp.skills}/>
                    </CardBodySection>
                }

                {
                    exp.activities != null &&
                    <CardBodySection>
                        <CardBodyTile>Activities</CardBodyTile>
                        <CardBodyItem description={exp.activities}/>
                    </CardBodySection>
                }

                {
                    exp.awards != null &&
                    <CardBodySection>
                        <CardBodyTile>Awards and Achievements</CardBodyTile>
                        <CardBodyItem description={exp.awards}/>
                    </CardBodySection>
                }

                {
                    exp.grade != null &&
                    <CardBodySection>
                        <CardBodyTile>Grade</CardBodyTile>
                        <CardBodyItem description={exp.grade}/>
                    </CardBodySection>
                }
            </CardBody>
        </Card>
    );
}

export default ExperienceCard;