import styled from "styled-components";
import ProfilePic from '../assets/profile_pic.webp';


const ProfileSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    gap: 12px;
    max-width: 90%;
    
    @media (min-width: 900px) {
        max-width: 45%;
    }
`;

const ProfileUpperSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;

    @media (max-width: 430px) {
        justify-content: center;
        flex-direction: column;
    }
`;

const ProfilePicture = styled.img`
    width: 100%; /* Let the image fill its container */
    max-width: 160px; /* Set a maximum width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    user-select: none;
    align-self: center;

    // @media (min-width: 768px) {
    //     max-width: 160px; /* Larger size for larger screens */
    // }
`;


const ProfileContainerRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // flex-basis: calc(100% - 140px);
    gap: 12px;

    @media (min-width: 768px) {
        // flex-basis: auto;
        gap: 0px;
    }
`

const ProfileSubSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const ProfileSubTitle = styled.h1`
    padding: 0;
    margin: 0;
    font-weight: 400;
    color: #ffffff80;

    font-size: 0.7rem; /* Smaller font for mobile */
    
    @media (min-width: 768px) {
        font-size: 0.8rem; /* Restore size for larger screens */
    }
`;

const ProfileSubContent = styled.h1`
    padding: 0;
    margin: 0;
    font-weight: 600;

    font-size: 1rem; /* Smaller font for mobile */

    @media (min-width: 768px) {
        font-size: 1.1rem; /* Restore size for larger screens */
    }
`;

const ProfileSubContentDescription = styled.h1`
    padding: 0;
    margin: 0;
    font-weight: 500;

    font-size: 0.9rem; /* Smaller font for mobile */
    
    @media (min-width: 768px) {
        font-size: 1rem; /* Restore size for larger screens */
    }
`

function ProfileCard() {

    return(
        <ProfileSection>
            <ProfileContainer>
                <ProfileUpperSection>
                    <ProfilePicture src={ProfilePic}></ProfilePicture>

                    <ProfileContainerRight>
                        <ProfileSubSection>
                            <ProfileSubTitle>Name</ProfileSubTitle>
                            <ProfileSubContent>Joe el Hachem</ProfileSubContent>
                        </ProfileSubSection>

                        <ProfileSubSection>
                            <ProfileSubTitle>Nationality</ProfileSubTitle>
                            <ProfileSubContent>Lebanese</ProfileSubContent>
                        </ProfileSubSection>

                        <ProfileSubSection>
                            <ProfileSubTitle>Languages</ProfileSubTitle>
                            <ProfileSubContent>English, French, Arabic</ProfileSubContent>
                        </ProfileSubSection>

                        <ProfileSubSection>
                            <ProfileSubTitle>Values and Beliefs</ProfileSubTitle>
                            <ProfileSubContent>Integrity, Elegance, Respect</ProfileSubContent>
                        </ProfileSubSection>
                    </ProfileContainerRight>
                </ProfileUpperSection>
                
                <ProfileSubSection>
                    <ProfileSubTitle>Description</ProfileSubTitle>
                    <ProfileSubContentDescription>Driven by ambition and a strong work ethic, I committed my few years before graduating to personal and professional growth, showcasing a commitment to excellence and elegance.</ProfileSubContentDescription>
                </ProfileSubSection>
            </ProfileContainer>
        </ProfileSection>
    );
}

export default ProfileCard;